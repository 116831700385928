/* autoprefixer: off */
@import "~normalize.css/normalize.css";

// This style is needed to make vendor_styles.scss be rebuilt with a new hash,
// so that browsers load a new version. Otherwise, there will be CORS-related error
// that won't be fixed by itself unless browser cache is cleared
// This code can be deleted if a new import is added to this file or 1 year has passed since the release of this code
// (around August 2025)
html {
  visibility: inherit;
}
